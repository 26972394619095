export default {
  methods: {
    checkGeo: function checkGeo() {
      if (this.loggedIn && this.appMain.fraudDetector && !this.appMain.fraudDetector.verificationCompleted && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function () {
          console.log('geolocation');
        });
      }
    }
  }
};
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.slice.js";
import Vue from 'vue';
export var addComponents = function addComponents(requires) {
  requires.keys().forEach(function (next) {
    var preparedName = next.slice(2, next.length - 4);
    Vue.component(preparedName, requires(next).default);
  });
};
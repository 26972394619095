//
//
//
//
//
//
//
export default {
  name: 'SocialLink',
  props: {
    social: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isShowAppBadges: {
      type: Boolean,
      default: false
    }
  }
};
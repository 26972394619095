//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Base from '@/mixins/Base';
export default {
  name: 'LazyComponent',
  mixins: [Base],
  props: {
    type: String,
    lazyHydrateType: {},
    once: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    lazyType: function lazyType() {
      return this.type || (this.isDesktop ? null : 'intersection');
    }
  }
};
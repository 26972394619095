//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import autoLockStatus from '@/dictionaries/autoLockStatus';
export default {
  data: function data() {
    return {
      autoLockStatus: autoLockStatus
    };
  },
  computed: {
    autoLock: function autoLock() {
      return this.$store.getters['player/autoLock'];
    }
  },
  methods: {
    openAutoLockModal: function openAutoLockModal() {
      this.$store.dispatch('modals/setAutoLockModal', {
        isOpen: true
      });
    },
    openSupport: function openSupport() {
      this.$store.$support.open();
    },
    close: function close() {
      this.$store.dispatch('player/setAutoLockModal', {
        isOpen: false
      });
    },
    closeApproved: function closeApproved() {
      var _this = this;

      if (this.autoLock === autoLockStatus.approved) {
        setTimeout(function () {
          _this.$store.dispatch('player/setAutoLock', null);
        }, 3000);
      }
    }
  },
  mounted: function mounted() {
    this.closeApproved();
  },
  updated: function updated() {
    this.closeApproved();
  }
};
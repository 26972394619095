export default {
  items: [{
    src: 'statics/payment/mc2.svg',
    alt: 'master card'
  }, {
    src: 'statics/payment/visa1.svg',
    alt: 'visa'
  }, {
    src: 'statics/payment/ecoPayz.svg',
    alt: 'ecoPayz'
  }, {
    src: 'statics/payment/zimpler.svg',
    alt: 'zimpler'
  }, {
    src: 'statics/payment/skrill.svg',
    alt: 'skrill'
  }, {
    src: 'statics/payment/neteller.svg',
    alt: 'neteller'
  }, {
    src: 'statics/payment/klarna.svg',
    alt: 'klarna'
  }, {
    src: 'statics/payment/bitcoin.svg',
    alt: 'bitcoin'
  }]
};
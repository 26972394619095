//
//
//
//
//
//
//
//
//
//
//
import Base from '@/mixins/Base';
export default {
  name: 'PageSection',
  mixins: [Base],
  props: {
    config: {}
  }
};
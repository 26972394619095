import "core-js/modules/es6.array.find.js";
import "core-js/modules/es7.object.values.js";
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {};
  },
  computed: {
    isConfirmationState: function isConfirmationState() {
      return this.depositsCount < 1 && this.showRewards;
    },
    depositsCount: function depositsCount() {
      return this.$store.getters['player/profile'].depositsCount;
    },
    rewards: function rewards() {
      return this.$store.getters['rewards/rewards'];
    },
    showRewards: function showRewards() {
      return this.rewards && !!Object.values(this.rewards).find(function (reward) {
        return reward.available && !reward.completed;
      });
    },
    hasDeposits: function hasDeposits() {
      return this.$store.getters['rewards/hasDeposits'];
    }
  },
  methods: {
    openModal: function openModal() {
      if (this.isConfirmationState && !this.hasDeposits) {
        this.$store.dispatch('modals/setRewardsModal', {
          isOpen: true
        });
        this.$store.gaModal.openReward({
          event: 'rewards',
          step: 'info',
          location: 'offer'
        });
      } else {
        this.$store.dispatch('modals/setPresetPackageModal', {
          isOpen: true
        });
        this.$store.gaBannerPresetPackage.click({
          buttonName: 'offer',
          quantity: '',
          location: 'button_right'
        });
        this.$store.gaBannerPresetPackage.openPopup({
          formName: 'bonus_offer',
          quantity: this.depositsCount,
          location: 'bonus'
        });
      }
    }
  }
};
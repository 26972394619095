export default {
  namespaced: true,
  state: function state() {
    return {
      info: null,
      isWebview: null
    };
  },
  getters: {
    info: function info(state) {
      return state.info;
    },
    isWebview: function isWebview(state) {
      return state.isWebview;
    }
  },
  mutations: {
    setInfo: function setInfo(state, payload) {
      state.info = payload;
    },
    setIsWebview: function setIsWebview(state, payload) {
      state.isWebview = payload;
    }
  },
  actions: {
    setInfo: function setInfo(_ref, payload) {
      var commit = _ref.commit;
      commit('setInfo', payload);
    },
    setIsWebview: function setIsWebview(_ref2, payload) {
      var commit = _ref2.commit;
      commit('setIsWebview', payload);
    }
  }
};
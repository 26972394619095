import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
export var calculateCoins = function calculateCoins(_ref) {
  var prizes = _ref.prizes;
  return prizes === null || prizes === void 0 ? void 0 : prizes.reduce(function (acc, prize) {
    return acc + (prize.coins || parseInt(prize, 10));
  }, 0);
};
export var calculateEntries = function calculateEntries(_ref2) {
  var prizes = _ref2.prizes;
  return prizes === null || prizes === void 0 ? void 0 : prizes.reduce(function (acc, prize) {
    return acc + prize.entries;
  }, 0);
};
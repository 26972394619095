import "core-js/modules/es6.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Base from '@/mixins/Base';
export default {
  mixins: [Base],
  props: {
    game: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    category: {
      type: String,
      default: ''
    },
    isCollectionGame: {
      type: Boolean,
      default: false
    },
    isUseImageSmallHorizontal: Boolean,
    lightVersion: Boolean,
    isUseImgTag: Boolean,
    hidePreview: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    img: function img() {
      return this.isUseImageSmallHorizontal ? this.game.imageSmallHorizontal : this.game.img || this.game.logo;
    },
    loggedIn: function loggedIn() {
      return this.$store.getters['player/loggedIn'];
    },
    profile: function profile() {
      return this.$store.getters['player/profile'];
    },
    providers: function providers() {
      return this.$store.getters['games/providers'];
    },
    favorites: function favorites() {
      return this.$store.getters['player/favoriteGames'];
    },
    currency: function currency() {
      return this.$store.getters['player/gameMode'] === 'SweepStakes' ? 'entries' : 'coins';
    },
    depositsCount: function depositsCount() {
      return this.$store.getters['player/profile'].depositsCount;
    },
    favObj: function favObj() {
      if (this.favorites) {
        var favObj = {}; // eslint-disable-next-line no-plusplus

        for (var i = 0; i < this.favorites.length; i++) {
          favObj[this.favorites[i]] = this.favorites[i];
        }

        return favObj;
      }

      return null;
    },
    nativeApp: function nativeApp() {
      return this.$store.$app.nativeApp;
    },
    locked: function locked() {
      var _this$game;

      return (_this$game = this.game) === null || _this$game === void 0 ? void 0 : _this$game.isOnlyForApp;
    },
    isNew: function isNew() {
      var _this$game2;

      return ((_this$game2 = this.game) === null || _this$game2 === void 0 ? void 0 : _this$game2.isNew) && (this.$route.path === '/' || this.$route.path === '/issues/all-games/');
    },
    show: function show() {
      return !this.locked || this.depositsCount > 0 && this.loggedIn && !!this.nativeApp.showLockedItem();
    }
  },
  methods: {
    toggleFavorite: function toggleFavorite() {
      var _this$game3, _this$category;

      var favoriteData = {
        location: (_this$game3 = this.game) === null || _this$game3 === void 0 ? void 0 : _this$game3.slug,
        formName: (_this$category = this.category) !== null && _this$category !== void 0 ? _this$category : ''
      };
      this.$store.dispatch('player/toggleFavorite', this.game.id);
      var isInFavorites = this.game.id === this.favObj[this.game.id];
      if (isInFavorites) this.$store.ga.removeFavorite(favoriteData);else this.$store.ga.addFavorite(favoriteData);
      this.$q.notify({
        message: !isInFavorites ? 'Game added to your favorites' : 'Game removed from your favorites',
        position: this.isDesktop ? 'bottom-right' : 'top',
        timeout: 1500,
        icon: 'img:statics/game-preview/icon-favorite.svg',
        classes: 'notificaton-box notificaton-box--with-icon'
      });
    },
    getProvider: function getProvider(id) {
      return this.providers.find(function (p) {
        return p.value === id;
      });
    },
    openGamePreview: function openGamePreview() {
      var _this$category2;

      this.$store.dispatch('modals/setGamePreviewModal', {
        isOpen: true,
        data: {
          game: this.game,
          category: this.category
        }
      });
      this.$store.gaRegister.open({
        location: this.game.slug,
        buttonName: 'gameplay',
        formName: (_this$category2 = this.category) !== null && _this$category2 !== void 0 ? _this$category2 : ''
      });
    },
    linkTo: function linkTo(_ref) {
      var page = _ref.page,
          url = _ref.url;
      return this.$store.getters['lang/linkTo']({
        page: page,
        params: url
      });
    },
    openGetAppModal: function openGetAppModal() {
      this.$store.dispatch('modals/setGetAppModal', {
        isOpen: true
      });
    },
    openGame: function openGame(e) {
      if (!this.isDesktop && this.isCollectionGame) return;
      e.stopPropagation();

      if (this.locked) {
        this.openGetAppModal();
      } else if (this.hidePreview || !this.game.gamePlayInfo) {
        if (this.loggedIn) {
          this.$store.ga.gameplay({
            step: 'start',
            type: this.currency
          });
        }

        var game = {
          id: this.game.slug,
          isMoney: true
        };
        this.$store.dispatch('game/getGame', game);
      } else {
        this.openGamePreview();
      }
    }
  }
};
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Base from '@/mixins/Base';
import Router from '@/mixins/Router';
import HeaderMobile from '@/components/Controls/HeaderMobile';
import '@/components/icons/navigation';
export default {
  name: 'MobNavigation',
  mixins: [Base, Router],
  components: {
    'app-mobile-menu': HeaderMobile,
    'app-magic-boxes': function appMagicBoxes() {
      return import(
      /* webpackChunkName: "app-races-leaderboard-modal" */
      '@/components/MagicBoxes/MagicBoxesModal');
    }
  },
  computed: {
    tournament: function tournament() {
      return this.$store.getters['tournament/tournament'];
    },
    gameMode: function gameMode() {
      return this.$store.getters['player/gameMode'];
    },
    isSweepStakes: function isSweepStakes() {
      return this.gameMode === 'SweepStakes';
    },
    links: function links() {
      var _this = this;

      var routes = this.$router.options.routes.find(function (route) {
        return route.meta.mainNav === true;
      });
      var navLinks = routes.children.filter(function (route) {
        if (route.meta.label === 'races' && _this.isSweepStakes) return false;
        return route.meta.isMobMenuLink === true;
      }).sort(function (routeA, routeB) {
        return routeA.meta.sort - routeB.meta.sort;
      });
      return navLinks.map(function (link) {
        var to = {
          name: link.name
        };
        return {
          to: to,
          label: link.meta.label,
          id: link.meta.id,
          requiresAuth: !!link.meta.requiresAuth,
          isShowCondition: !!link.meta.isShowCondition
        };
      });
    },
    loggedIn: function loggedIn() {
      return this.$store.getters['player/loggedIn'];
    },
    games: function games() {
      return this.$store.$routerHelper.linkTo({
        page: 'games'
      });
    },
    balance: function balance() {
      return this.$store.getters['balance/playerBalance'];
    },
    appMain: function appMain() {
      return this.$store.getters['player/profile'];
    },
    hasDeposits: function hasDeposits() {
      return this.appMain.hasDeposits;
    }
  },
  methods: {
    openAuthModal: function openAuthModal(value) {
      this.$store.dispatch('modals/setAuthModal', {
        isOpen: true,
        activeTab: value
      });
      this.$store.dispatch('auth/setLocationAuth', 'tabbar');
      this.$store.ga.clickButton({
        location: 'tabbar',
        buttonName: 'registration'
      });
    },
    openCash: function openCash() {
      if (this.balance.winnings <= 0) {
        this.$store.gaCash.deposit({
          location: 'footer',
          type: '',
          step: 'view_payments_info'
        });
        this.$store.$cash.open({
          url: '/cash/deposit-by-money/'
        });
      } else {
        this.$store.gaCash.deposit({
          location: 'footer',
          type: '',
          step: 'payments_method'
        });
        this.$store.$cash.open({
          url: '/cash/deposit/'
        });
      }

      if (this.balance && (this.isSweepStakes && !Number(this.balance.entries) || !this.isSweepStakes && !Number(this.balance.tourPoints))) {
        this.$store.dispatch('popupPresetPackage/showPackage', {
          aboveCash: true
        });
      }
    },
    promoClickHandler: function promoClickHandler() {
      if (!this.loggedIn) {
        this.openAuthModal('signup');
      } else {
        this.$router.push('/promotions/');
      }
    },
    magicBoxClickHandler: function magicBoxClickHandler() {
      if (this.loggedIn) {
        this.$store.dispatch('modals/setMagicBoxesModal', {
          isOpen: true
        });
      } else {
        this.$store.dispatch('auth/setLocationAuth', 'left_menu');
        this.$store.dispatch('modals/setAuthModal', {
          isOpen: true,
          activeTab: 'signup'
        });
      }
    },
    wheelClickHandler: function wheelClickHandler() {
      if (!this.loggedIn) {
        this.openAuthModal('signup');
      } else {
        this.$store.dispatch('modals/setDailyWheelPopup', {
          isOpen: true
        });
      }
    },
    triggerSidebar: function triggerSidebar() {
      this.$emit('triggerSidebar');
    }
  }
};
var pages = {
  '/': {
    sections: [{
      id: 'activityFeed',
      shortTitle: '',
      title: '',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: false,
      showHeader: false,
      guestOrder: 1,
      userOrder: 1
    }, {
      id: 'pageTitle',
      shortTitle: '',
      title: '',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: true,
      showHeader: false,
      guestOrder: 2,
      userOrder: 2
    }, // {
    //   id: 'rating',
    //   shortTitle: '',
    //   title: '',
    //   sectionTitle: '',
    //   titleLabel: false,
    //   isIntersection: false,
    //   displayed: true,
    //   onlyForGuest: true,
    // },
    {
      id: 'topGames',
      shortTitle: '',
      title: 'tao_pia.section.topGames',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: false,
      showHeader: true,
      guestOrder: 3,
      userOrder: 3
    }, {
      id: 'favorite',
      shortTitle: '',
      title: 'tao.section.favoritesAndRecent',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: false,
      showHeader: true,
      guestOrder: 4,
      userOrder: 4
    }, {
      id: 'holdAndLink',
      shortTitle: '',
      title: 'tao.section.holdAndLink',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: false,
      showHeader: true,
      guestOrder: 5,
      userOrder: 5
    }, {
      id: 'jackpotGames',
      shortTitle: '',
      title: 'tao.section.jackpotGames',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: false,
      showHeader: true,
      guestOrder: 6,
      userOrder: 10
    }, {
      id: 'promotionMain',
      shortTitle: '',
      title: '',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: false,
      showHeader: false,
      guestOrder: 7,
      userOrder: 7
    }, {
      id: 'benefits',
      shortTitle: '',
      title: '',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: true,
      showHeader: false,
      guestOrder: 8,
      userOrder: 8
    }, // {
    //   id: 'questBanner',
    //   shortTitle: '',
    //   title: '',
    //   sectionTitle: '',
    //   titleLabel: false,
    //   isIntersection: false,
    //   displayed: true,
    //   onlyForGuest: false,
    //   showHeader: false,
    //   guestOrder: 9,
    //   userOrder: 11,
    // },
    {
      id: 'comments',
      shortTitle: '',
      title: '',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: true,
      showHeader: false,
      guestOrder: 10,
      userOrder: 10
    }, {
      id: 'jackpot',
      shortTitle: 'play.sections.jackpot.shortTitle',
      title: 'play.sections.jackpot.title',
      sectionTitle: 'play.sections.jackpot.sectionTitle',
      subtitle: 'play.sections.jackpot.subtitle',
      isIntersection: false,
      displayed: true,
      guestOrder: 11,
      userOrder: 9
    }, {
      id: 'fruitGames',
      shortTitle: '',
      title: 'tao.section.fruitGames',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: false,
      showHeader: true,
      guestOrder: 12,
      userOrder: 12
    }, {
      id: 'fishingGames',
      shortTitle: '',
      title: 'tao.section.fishingGames',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: false,
      showHeader: true,
      guestOrder: 13,
      userOrder: 13
    }, {
      id: 'hotGames',
      shortTitle: '',
      title: 'tao.section.hotGames',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: false,
      showHeader: true,
      guestOrder: 14,
      userOrder: 14
    }, {
      id: 'openRegistrationForm',
      shortTitle: '',
      title: '',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: true,
      showHeader: false,
      guestOrder: 15,
      userOrder: 15
    } // {
    //   id: 'recentlyPlayed',
    //   shortTitle: 'game.sections.recentlyPlayed.shortTitle',
    //   title: 'game.sections.recentlyPlayed.title',
    //   sectionTitle: 'game.sections.recentlyPlayed.sectionTitle',
    //   isIntersection: false,
    //   displayed: 'myGamePage.sectionsState',
    //   onlyForGuest: false,
    //   showHeader: true,
    // },
    // {
    //   id: 'jackpotSlots',
    //   shortTitle: '',
    //   title: '',
    //   sectionTitle: '',
    //   titleLabel: false,
    //   isIntersection: false,
    //   displayed: true,
    //   onlyForGuest: false,
    //   showHeader: false,
    // },
    // {
    //   id: 'collections',
    //   shortTitle: '',
    //   title: '',
    //   sectionTitle: '',
    //   titleLabel: false,
    //   isIntersection: false,
    //   displayed: true,
    //   onlyForGuest: false,
    //   showHeader: false,
    // },
    // {
    //   id: 'staticRegInvite',
    //   shortTitle: '',
    //   title: '',
    //   sectionTitle: '',
    //   titleLabel: false,
    //   isIntersection: false,
    //   displayed: true,
    //   onlyForGuest: true,
    //   showHeader: false,
    // },
    ]
  },
  register: {
    sections: [{
      id: 'activityFeed',
      shortTitle: '',
      title: '',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: false,
      showHeader: false,
      guestOrder: 1,
      userOrder: 1
    }, {
      id: 'pageTitle',
      shortTitle: '',
      title: '',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: true,
      showHeader: false,
      guestOrder: 2,
      userOrder: 2
    }, // {
    //   id: 'rating',
    //   shortTitle: '',
    //   title: '',
    //   sectionTitle: '',
    //   titleLabel: false,
    //   isIntersection: false,
    //   displayed: true,
    //   onlyForGuest: true,
    // },
    {
      id: 'topGames',
      shortTitle: '',
      title: 'tao_pia.section.topGames',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: false,
      showHeader: true,
      guestOrder: 3,
      userOrder: 3
    }, {
      id: 'favorite',
      shortTitle: '',
      title: 'game.sections.favorite.title',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: false,
      showHeader: true,
      guestOrder: 4,
      userOrder: 4
    }, {
      id: 'holdAndLink',
      shortTitle: '',
      title: 'tao.section.holdAndLink',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: false,
      showHeader: true,
      guestOrder: 5,
      userOrder: 5
    }, {
      id: 'jackpotGames',
      shortTitle: '',
      title: 'tao.section.jackpotGames',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: false,
      showHeader: true,
      guestOrder: 6,
      userOrder: 10
    }, {
      id: 'promotionMain',
      shortTitle: '',
      title: '',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: false,
      showHeader: false,
      guestOrder: 7,
      userOrder: 7
    }, {
      id: 'benefits',
      shortTitle: '',
      title: '',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: true,
      showHeader: false,
      guestOrder: 8,
      userOrder: 8
    }, {
      id: 'questBanner',
      shortTitle: '',
      title: '',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: false,
      showHeader: false,
      guestOrder: 9,
      userOrder: 11
    }, {
      id: 'comments',
      shortTitle: '',
      title: '',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: true,
      showHeader: false,
      guestOrder: 10,
      userOrder: 10
    }, {
      id: 'jackpot',
      shortTitle: 'play.sections.jackpot.shortTitle',
      title: 'play.sections.jackpot.title',
      sectionTitle: 'play.sections.jackpot.sectionTitle',
      subtitle: 'play.sections.jackpot.subtitle',
      isIntersection: false,
      displayed: true,
      guestOrder: 11,
      userOrder: 9
    }, {
      id: 'fruitGames',
      shortTitle: '',
      title: 'tao.section.fruitGames',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: false,
      showHeader: true,
      guestOrder: 12,
      userOrder: 12
    }, {
      id: 'fishingGames',
      shortTitle: '',
      title: 'tao.section.fishingGames',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: false,
      showHeader: true,
      guestOrder: 13,
      userOrder: 13
    }, {
      id: 'hotGames',
      shortTitle: '',
      title: 'tao.section.hotGames',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: false,
      showHeader: true,
      guestOrder: 14,
      userOrder: 14
    }, {
      id: 'openRegistrationForm',
      shortTitle: '',
      title: '',
      sectionTitle: '',
      titleLabel: false,
      isIntersection: false,
      displayed: true,
      onlyForGuest: true,
      showHeader: false,
      guestOrder: 15,
      userOrder: 15
    } // {
    //   id: 'recentlyPlayed',
    //   shortTitle: 'game.sections.recentlyPlayed.shortTitle',
    //   title: 'game.sections.recentlyPlayed.title',
    //   sectionTitle: 'game.sections.recentlyPlayed.sectionTitle',
    //   isIntersection: false,
    //   displayed: 'myGamePage.sectionsState',
    //   onlyForGuest: false,
    //   showHeader: true,
    // },
    // {
    //   id: 'jackpotSlots',
    //   shortTitle: '',
    //   title: '',
    //   sectionTitle: '',
    //   titleLabel: false,
    //   isIntersection: false,
    //   displayed: true,
    //   onlyForGuest: false,
    //   showHeader: false,
    // },
    // {
    //   id: 'collections',
    //   shortTitle: '',
    //   title: '',
    //   sectionTitle: '',
    //   titleLabel: false,
    //   isIntersection: false,
    //   displayed: true,
    //   onlyForGuest: false,
    //   showHeader: false,
    // },
    // {
    //   id: 'staticRegInvite',
    //   shortTitle: '',
    //   title: '',
    //   sectionTitle: '',
    //   titleLabel: false,
    //   isIntersection: false,
    //   displayed: true,
    //   onlyForGuest: true,
    //   showHeader: false,
    // },
    ]
  },
  game: {
    sections: [{
      id: 'myLevel',
      shortTitle: 'game.sections.myLevel.shortTitle',
      title: 'game.sections.myLevel.title',
      isIntersection: false,
      showIcon: true,
      displayed: true
    } // {
    //   id: 'myPrizes',
    //   shortTitle: 'game.sections.myPrizes.shortTitle',
    //   title: 'game.sections.myPrizes.title',
    //   sectionTitle: 'game.sections.myPrizes.sectionTitle',
    //   subtitle: 'game.sections.myPrizes.subtitle',
    //   isIntersection: false,
    //   displayed: true,
    // },
    // {
    //   id: 'activePromotion',
    //   shortTitle: 'game.sections.activePromotion.shortTitle',
    //   title: 'game.sections.activePromotion.title',
    //   sectionTitle: 'game.sections.activePromotion.sectionTitle',
    //   subtitle: 'game.sections.activePromotion.subtitle',
    //   isIntersection: false,
    //   displayed: 'myGamePage.sectionsState',
    // },
    // {
    //   id: 'verification',
    // shortTitle: 'game.sections.verification.shortTitle',
    // title: 'game.sections.verification.title',
    // sectionTitle: 'game.sections.verification.sectionTitle',
    // subtitle: 'game.sections.verification.subtitle',
    //   isIntersection: false,
    //   showIcon: false,
    //   displayed: 'myGamePage.sectionsState',
    // },
    ]
  },
  races: {
    sections: [{
      id: 'timetable',
      shortTitle: 'tournaments.sections.timetable.shortTitle',
      title: 'tournaments.sections.timetable.title',
      sectionTitle: 'tournaments.sections.timetable.sectionTitle',
      subtitle: 'tournaments.sections.timetable.subtitle',
      isIntersection: false,
      displayed: true
    }]
  },
  profile: {
    sections: [// {
    //   id: 'account',
    //   shortTitle: 'profile.sections.account.shortTitle',
    //   title: 'profile.sections.account.title',
    //   isIntersection: false,
    //   displayed: true,
    //   noHeader: true,
    // },
    // {
    //   id: 'contactsInfo',
    //   shortTitle: 'profile.sections.contactsInfo.shortTitle',
    //   title: 'profile.sections.contactsInfo.title',
    //   subtitle: 'profile.sections.contactsInfo.subtitle',
    //   sectionTitle: 'profile.sections.contactsInfo.sectionTitle',
    //   isIntersection: false,
    //   displayed: true,
    // },
    {
      id: 'subscriptions',
      shortTitle: 'profile.sections.subscriptions.shortTitle',
      title: 'profile.sections.subscriptions.title',
      // sectionTitle: 'profile.sections.subscriptions.sectionTitle',
      // subtitle: 'profile.sections.subscriptions.subtitle',
      isIntersection: false,
      displayed: true
    }, {
      id: 'security',
      shortTitle: 'Сhange password',
      title: 'Сhange password',
      // sectionTitle: 'profile.sections.security.sectionTitle',
      // subtitle: 'profile.sections.security.subtitle',
      isIntersection: false,
      displayed: true
    }]
  },
  page: {
    sections: []
  },
  games: {
    sections: [{
      id: 'aboutGame',
      shortTitle: 'games.sections.aboutGame.shortTitle',
      title: 'games.sections.aboutGame.title',
      isIntersection: false,
      displayed: true
    }, {
      id: 'relatedGame',
      shortTitle: 'games.sections.relatedGame.shortTitle',
      title: 'games.sections.relatedGame.title',
      subtitle: 'games.sections.relatedGame.subtitle',
      i18nVars: 'statics/i18nVars',
      isIntersection: false,
      displayed: true
    }, {
      id: 'slotFeature',
      shortTitle: 'games.sections.slotFeature.shortTitle',
      title: 'games.sections.slotFeature.title',
      subtitle: 'games.sections.slotFeature.subtitle',
      isIntersection: false,
      displayed: true
    }]
  },
  'how-it-works': {
    sections: [{
      id: 'howItWorks',
      shortTitle: 'howItWorks.sections.howWork.shortTitle',
      title: 'howItWorks.sections.howWork.title',
      isIntersection: false,
      displayed: true
    }]
  },
  'game-vendors': {
    sections: [{
      id: 'aboutProvider',
      shortTitle: 'providers.sections.aboutProvider.shortTitle',
      title: 'providers.sections.aboutProvider.title',
      isIntersection: false,
      displayed: true
    }, {
      id: 'featuredGame',
      shortTitle: 'providers.sections.featuredGame.shortTitle',
      title: 'providers.sections.featuredGame.title',
      subtitle: 'providers.sections.featuredGame.subtitle',
      isIntersection: false,
      displayed: true
    }, {
      id: 'gamesProvider',
      shortTitle: 'providers.sections.gameProvider.shortTitle',
      title: 'providers.sections.gameProvider.title',
      i18nVars: 'statics/i18nVars',
      isIntersection: false,
      displayed: true
    }]
  },
  'game-issues': {
    sections: [{
      id: 'categoryName',
      shortTitle: 'category.sections.categoryName.shortTitle',
      title: 'category.sections.categoryName.title',
      isIntersection: false,
      displayed: true
    }, {
      id: 'categoryFeaturedGame',
      shortTitle: 'category.sections.categoryFeaturedGame.shortTitle',
      title: 'category.sections.categoryFeaturedGame.title',
      subtitle: 'category.sections.categoryFeaturedGame.subtitle',
      isIntersection: false,
      displayed: true
    }, {
      id: 'categoryAllGames',
      shortTitle: 'category.sections.categoryAllGames.shortTitle',
      title: 'category.sections.categoryAllGames.title',
      i18nVars: 'statics/i18nVars',
      isIntersection: false,
      displayed: true
    }]
  },
  contacts: {
    sections: [{
      id: 'contactTitle',
      shortTitle: 'contacts.sections.contactTitle.shortTitle',
      title: 'contacts.sections.contactTitle.title',
      isIntersection: false,
      displayed: true
    }]
  },
  payments: {
    sections: [{
      id: 'paymentsTitle',
      shortTitle: 'payments.sections.paymentsTitle.shortTitle',
      title: 'payments.sections.paymentsTitle.title',
      subtitle: 'payments.sections.paymentsTitle.subtitle',
      isIntersection: false,
      displayed: true
    }]
  },
  cashIframe: {
    sections: []
  },
  venue: {
    sections: []
  },
  'player-activity': {
    sections: []
  }
};
export default pages;
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.regexp.split.js";
//
//
//
//
//
//
export default {
  name: 'BalanceCounter',
  props: {
    value: {
      type: Number,
      default: 0
    },
    decimals: {
      type: Number,
      default: null
    }
  },
  data: function data() {
    return {
      currentValue: 0,
      animate: 0
    };
  },
  computed: {
    getCurrentValue: function getCurrentValue() {
      // return !this.getDecimals ? this.currentValue : this.currentValue.toFixed(this.getDecimals)
      return this.currentValue.toFixed(this.getDecimals);
    },
    getDecimals: function getDecimals() {
      var _this$decimals, _this$value$toLocaleS;

      return (_this$decimals = this.decimals) !== null && _this$decimals !== void 0 ? _this$decimals : ((_this$value$toLocaleS = this.value.toLocaleString('en-US', {
        maximumSignificantDigits: 18
      }).split('.')[1]) === null || _this$value$toLocaleS === void 0 ? void 0 : _this$value$toLocaleS.length) || 0;
    },
    getValuesDiff: function getValuesDiff() {
      return this.value - this.currentValue;
    }
  },
  methods: {
    startAnimation: function startAnimation() {
      var _this = this;

      var startTime;
      var currentValue = this.currentValue;
      var diff = this.getValuesDiff;
      var duration = Math.max(839.93 + 241.53 * Math.log(Math.abs(diff)), 500); // const decimalsBase = 10 ** this.getDecimals;

      var step = function step(currentTime) {
        if (!startTime) {
          startTime = currentTime;
        }

        var progress = Math.min((currentTime - startTime) / duration, 1);
        _this.currentValue = progress * diff + currentValue; // this.currentValue = Math.floor((progress * diff + currentValue) * decimalsBase) / decimalsBase;

        if (progress < 1) {
          _this.animate = requestAnimationFrame(step);
        }
      };

      this.animate = requestAnimationFrame(step);
    },
    stopAnimation: function stopAnimation() {
      cancelAnimationFrame(this.animate);
      this.animate = 0;
    }
  },
  watch: {
    value: function value() {
      if (this.animate) {
        this.stopAnimation();
      }

      if (this.getValuesDiff > 0) {
        this.startAnimation();
      } else {
        this.currentValue = this.value;
      }
    }
  },
  created: function created() {
    this.currentValue = this.value;
  },
  destroyed: function destroyed() {
    this.stopAnimation();
  }
};
export default {
  namespaced: true,
  state: function state() {
    return {
      isShowCashModal: false,
      cashMode: ''
    };
  },
  getters: {
    isShowCashModal: function isShowCashModal(state) {
      return state.isShowCashModal;
    },
    cashMode: function cashMode(state) {
      return state.cashMode;
    }
  },
  mutations: {
    setCashModalVisibility: function setCashModalVisibility(state, payload) {
      state.isShowCashModal = payload;
    },
    setCashMode: function setCashMode(state, payload) {
      state.cashMode = payload;
    }
  },
  actions: {
    changeCashModalVisibility: function changeCashModalVisibility(_ref, payload) {
      var commit = _ref.commit;
      commit('setCashModalVisibility', payload);
    }
  }
};
export default function (_ref) {
  var store = _ref.store;
  if (process.env.SERVER) return;

  store.oneSignalStart = function () {
    var onesignalAppId = store.getters['player/profile'].onesignalAppId;
    if (!onesignalAppId) return;
    setTimeout(function () {
      var oneSignalSDKScript = document.createElement('script');
      oneSignalSDKScript.src = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js';
      oneSignalSDKScript.async = 'async';
      document.head.appendChild(oneSignalSDKScript);
    }, 10000);
    window.OneSignal = window.OneSignal || [];
    window.OneSignal.push(function () {
      // This registers the workers at the root scope, which is allowed by the HTTP header "Service-Worker-Allowed: /"
      window.OneSignal.SERVICE_WORKER_PARAM = {
        scope: '/'
      };
    });
    window.OneSignal.push(['init', {
      appId: onesignalAppId,
      // allowLocalhostAsSecureOrigin: true,
      autoRegister: false,
      path: '/js/',
      notifyButton: {
        enable: false
        /* Set to false to hide */

      },

      /* httpPermissionRequest: {
            enable: true
        } */
      promptOptions: {
        /* These prompt options values configure both the HTTP prompt and the HTTP popup. */

        /* actionMessage limited to 90 characters */
        actionMessage: "\u0425\u043E\u0442\u0438\u0442\u0435 \u043F\u043E\u043B\u0443\u0447\u0430\u0442\u044C \u043D\u043E\u0432\u043E\u0441\u0442\u0438 \u0438\u0433\u0440\u043E\u0432\u043E\u0433\u043E \u043A\u043B\u0443\u0431\u0430 FUNRIZE\u2122 \u0438 \u0441\u0435\u043A\u0440\u0435\u0442\u043D\u044B\u0435 \u0431\u043E\u043D\u0443\u0441-\u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u044F?",

        /* acceptButtonText limited to 15 characters */
        acceptButtonText: 'Подписаться',

        /* cancelButtonText limited to 15 characters */
        cancelButtonText: 'Не сейчас'
      }
    }]);

    function getOnesignalId() {
      window.OneSignal.getUserId().then(function (userId) {
        var date = new Date(new Date().getTime() + 90000 * 1000000);
        document.cookie = "opensignalId=".concat(userId, "; path=/; expires=").concat(date.toUTCString());
      });
    }

    setTimeout(function () {
      window.OneSignal.push(function () {
        window.OneSignal.showHttpPrompt();
        getOnesignalId();
      });
    }, 30000);
    window.OneSignal.push(function () {
      getOnesignalId();
    });
  };

  store.oneSignalStart();
}
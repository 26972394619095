export default {
  namespaced: true,
  getters: {
    tournament: function tournament(state, getters, rootState, rootGetters) {
      return rootGetters['tournament/tournament'];
    },
    isSubscribed: function isSubscribed(state, getters, rootState, rootGetters) {
      if (!getters.tournament) return false;
      return rootGetters['tournament/isSubscribed'](getters.tournament.id);
    },
    sectionsState: function sectionsState(state, getters, rootState, rootGetters) {
      return {
        activePromotion: !!rootGetters['balance/activeBonus'],
        favorite: rootGetters['player/favoriteGames'].length > 0,
        recentlyPlayed: rootGetters['player/lastGames'].length > 0,
        myTournament: getters.isSubscribed
      };
    }
  }
};
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AppBadges from '@/mixins/AppBadges';
export default {
  name: 'GetAppBadges',
  mixins: [AppBadges],
  computed: {
    profileHasAutoPassword: function profileHasAutoPassword() {
      var _this$$store$getters$;

      return (_this$$store$getters$ = this.$store.getters['player/profile']) === null || _this$$store$getters$ === void 0 ? void 0 : _this$$store$getters$.hasAutoPassword;
    }
  },
  methods: {
    onBadgeClick: function onBadgeClick(os) {
      if (!this.profileHasAutoPassword) {
        window.location.href = os === 'ios' ? this.nativeApp.getIOSLinkRaw() : this.nativeApp.getAndroidLinkRaw();
      } else {
        this.$store.dispatch('modals/setGetAppModal', {
          isOpen: true
        });
      }
    }
  }
};
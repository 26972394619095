import Vue from 'vue';
export default (function (_ref) {
  var store = _ref.store;
  store.$eventBus = new Vue();
  store.$events = {
    CHANGE_JACKPOT: 'CHANGE_JACKPOT',
    CHANGE_PROVIDER: 'CHANGE_PROVIDER',
    CHANGE_AUTO_LOCK_STATUS: 'CHANGE_AUTO_LOCK_STATUS',
    SHOW_MAGIC_BOX: 'SHOW_MAGIC_BOX',
    SHOW_QUEST_WIN_ANIMATION: 'SHOW_QUEST_WIN_ANIMATION'
  };
});
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Btn',
  props: {
    type: {
      type: String,
      default: 'button'
    },
    label: String,
    color: String,
    ripple: Boolean,
    icon: String,
    iconRight: String,
    filled: Boolean,
    transparent: Boolean,
    disable: Boolean,
    noCaps: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    btnClass: function btnClass() {
      var btnClass = '';
      if (this.filled) btnClass = 'q-btn--accent-grad';
      if (this.transparent) btnClass = 'q-btn--tranparent';
      return btnClass;
    }
  },
  methods: {
    clicked: function clicked(event) {
      this.$emit('click', event);
    }
  }
};
import Vue from 'vue';
import Vuex from 'vuex';
/* Base stores */

import app from "./app";
import lang from "./lang";
import modals from "./modals";
import sidebar from "./sidebar";
import player from "./player";
import games from "./games";
import game from "./game";
import tournament from "./tournament";
import statics from "./static";
import balance from "./balance";
import auth from "./auth";
import jackpots from "./jackpots";
import cashModule from "./cash";
import page from "./page";
import popupPresetPackage from "./popupPresetPackage";
import promotions from "./promotions";
import referral from "./referral";
import support from "./support";
import device from "./device";
import rewards from "./rewards";
import collections from "./collections";
import quest from "./quest";
import dailyWheel from "./dailyWheel";
/* Page stores */

import mainPage from "./pages/mainPage";
import myGamePage from "./pages/myGamePage";
import tournamentsPage from "./pages/tournamentsPage";
import gamesPage from "./pages/gamesPage";
Vue.use(Vuex);
/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

export default function () {
  var Store = new Vuex.Store({
    modules: {
      /* Base stores */
      app: app,
      player: player,
      lang: lang,
      modals: modals,
      sidebar: sidebar,
      games: games,
      game: game,
      tournament: tournament,
      statics: statics,
      balance: balance,
      auth: auth,
      jackpots: jackpots,
      gamesPage: gamesPage,

      /* Page stores */
      mainPage: mainPage,
      myGamePage: myGamePage,
      tournamentsPage: tournamentsPage,
      cashModule: cashModule,
      page: page,
      popupPresetPackage: popupPresetPackage,
      promotions: promotions,
      referral: referral,
      support: support,
      device: device,
      rewards: rewards,
      collections: collections,
      quest: quest,
      dailyWheel: dailyWheel
    },
    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV
  });
  return Store;
}
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/var/www/piastercreek.com/piastercreek.com/funrize/node_modules/@babel/runtime-corejs2/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

export default {
  namespaced: true,
  state: function state() {
    return {
      authModal: {
        isOpen: false,
        activeTab: 'signup',
        errorMessage: null,
        socialBlockException: []
      },
      registrationCompleteModal: {
        isOpen: false
      },
      emailConfirmationModal: {
        isOpen: false,
        withRequest: false,
        wasOpened: false
      },
      termModal: {
        isOpen: false,
        type: null,
        anchor: null
      },
      checkEmailModal: {
        isOpen: false,
        data: {
          nickName: '',
          link: ''
        }
      },
      gameModal: {
        isOpen: false,
        isMoney: false
      },
      bonusDetails: {
        isOpen: false,
        data: null
      },
      exchangeCashModal: {
        isOpen: false
      },
      vipClubModal: {
        isOpen: false
      },
      deactivatePromoModal: {
        isOpen: false
      },
      profileRedirectModal: {
        isOpen: false,
        url: ''
      },
      autoLockModal: {
        isOpen: false
      },
      menuMobile: {
        isOpen: false
      },
      switchModal: {
        isOpen: false
      },
      gamePreviewModal: {
        isOpen: false
      },
      giftPopup: {
        isOpen: false
      },
      confirmation: {
        isOpen: false,
        isFromCash: false,
        isRegistrationFlow: false
      },
      changeNumber: {
        isOpen: false
      },
      welcomePopup: {
        isOpen: false
      },
      referralPopup: {
        isOpen: false
      },
      signUpBonusProgress: {
        isOpen: false
      },
      profileForm: {
        isOpen: false
      },
      addFacebook: {
        isOpen: false
      },
      signUpBonusPopup: {
        isOpen: false
      },
      dailyStreakPopup: {
        isOpen: false
      },
      getAppModal: {
        isOpen: false
      },
      getAppModalDesktop: {
        isOpen: false
      },
      getAppModalSoon: {
        isOpen: false
      },
      presetPackageModal: {
        isOpen: false,
        aboveCash: false
      },
      verificationPromoModal: {
        isOpen: false
      },
      confirmInfoPopup: {
        isOpen: false
      },
      dailyWheelPopup: {
        isOpen: false
      },
      rewardsModal: {
        isOpen: false
      },
      magicBoxesModal: {
        isOpen: false
      },
      magicBoxesInfoModal: {
        isOpen: false
      }
    };
  },
  getters: {
    confirmInfoPopup: function confirmInfoPopup(state) {
      return state.confirmInfoPopup;
    },
    dailyStreakPopup: function dailyStreakPopup(state) {
      return state.dailyStreakPopup;
    },
    authModal: function authModal(state) {
      return state.authModal;
    },
    registrationCompleteModal: function registrationCompleteModal(state) {
      return state.registrationCompleteModal;
    },
    emailConfirmationModal: function emailConfirmationModal(state) {
      return state.emailConfirmationModal;
    },
    termModal: function termModal(state) {
      return state.termModal;
    },
    checkEmailModal: function checkEmailModal(state) {
      return state.checkEmailModal;
    },
    gameModal: function gameModal(state) {
      return state.gameModal;
    },
    bonusDetails: function bonusDetails(state) {
      return state.bonusDetails;
    },
    exchangeCashModal: function exchangeCashModal(state) {
      return state.exchangeCashModal;
    },
    deactivatePromoModal: function deactivatePromoModal(state) {
      return state.deactivatePromoModal;
    },
    profileRedirectModal: function profileRedirectModal(state) {
      return state.profileRedirectModal;
    },
    autoLockModal: function autoLockModal(state) {
      return state.autoLockModal;
    },
    menuMobile: function menuMobile(state) {
      return state.menuMobile;
    },
    vipClubModal: function vipClubModal(state) {
      return state.vipClubModal;
    },
    switchModal: function switchModal(state) {
      return state.switchModal;
    },
    gamePreviewModal: function gamePreviewModal(state) {
      return state.gamePreviewModal;
    },
    giftPopup: function giftPopup(state) {
      return state.giftPopup;
    },
    confirmation: function confirmation(state) {
      return state.confirmation;
    },
    changeNumber: function changeNumber(state) {
      return state.changeNumber;
    },
    welcomePopup: function welcomePopup(state) {
      return state.welcomePopup;
    },
    referralPopup: function referralPopup(state) {
      return state.referralPopup;
    },
    signUpBonusProgress: function signUpBonusProgress(state) {
      return state.signUpBonusProgress;
    },
    profileForm: function profileForm(state) {
      return state.profileForm;
    },
    addFacebook: function addFacebook(state) {
      return state.addFacebook;
    },
    signUpBonusPopup: function signUpBonusPopup(state) {
      return state.signUpBonusPopup;
    },
    getAppModal: function getAppModal(state) {
      return state.getAppModal;
    },
    getAppModalDesktop: function getAppModalDesktop(state) {
      return state.getAppModalDesktop;
    },
    getAppModalSoon: function getAppModalSoon(state) {
      return state.getAppModalSoon;
    },
    presetPackageModal: function presetPackageModal(state) {
      return state.presetPackageModal;
    },
    dailyWheelPopup: function dailyWheelPopup(state) {
      return state.dailyWheelPopup;
    },
    verificationPromoModal: function verificationPromoModal(state) {
      return state.verificationPromoModal;
    },
    rewardsModal: function rewardsModal(state) {
      return state.rewardsModal;
    },
    magicBoxesInfoModal: function magicBoxesInfoModal(state) {
      return state.magicBoxesInfoModal;
    }
  },
  mutations: {
    setConfirmInfoPopup: function setConfirmInfoPopup(state, payload) {
      state.confirmInfoPopup = payload;
    },
    setDailyStreakPopup: function setDailyStreakPopup(state, payload) {
      state.dailyStreakPopup = payload;
    },
    setReferralPopup: function setReferralPopup(state, payload) {
      state.referralPopup = payload;
    },
    setWelcomePopup: function setWelcomePopup(state, payload) {
      state.welcomePopup = payload;
    },
    setAuthModal: function setAuthModal(state, payload) {
      state.authModal = payload;
    },
    setAuthModalErrorMessage: function setAuthModalErrorMessage(state, payload) {
      state.authModal = _objectSpread(_objectSpread({}, state.authModal), {}, {
        errorMessage: payload
      });
    },
    setRegistrationCompleteModal: function setRegistrationCompleteModal(state, payload) {
      state.registrationCompleteModal = payload;
    },
    setEmailConfirmationModal: function setEmailConfirmationModal(state, payload) {
      state.emailConfirmationModal = _objectSpread(_objectSpread({}, payload), {}, {
        wasOpened: payload.wasOpened || state.emailConfirmationModal.wasOpened
      });
    },
    setTermModal: function setTermModal(state, payload) {
      state.termModal = payload;
    },
    setCheckEmailModal: function setCheckEmailModal(state, payload) {
      state.checkEmailModal = payload;
    },
    setGameModal: function setGameModal(state, payload) {
      state.gameModal = payload;
    },
    setBonusDetails: function setBonusDetails(state, payload) {
      state.bonusDetails.isOpen = payload.isOpen;
      if (payload.data) state.bonusDetails.data = payload.data;else state.bonusDetails.data = null;
    },
    setExchangeCashModal: function setExchangeCashModal(state, payload) {
      state.exchangeCashModal = payload;
    },
    setDeactivatePromoModal: function setDeactivatePromoModal(state, payload) {
      state.deactivatePromoModal = payload;
    },
    setProfileRedirectModal: function setProfileRedirectModal(state, payload) {
      state.profileRedirectModal = payload;
    },
    setAutoLockModal: function setAutoLockModal(state, payload) {
      state.autoLockModal = payload;
    },
    setMenuMobile: function setMenuMobile(state, payload) {
      state.menuMobile = payload;
    },
    setVipClubModal: function setVipClubModal(state, payload) {
      state.vipClubModal = payload;
    },
    setSwitchModal: function setSwitchModal(state, payload) {
      state.switchModal = payload;
    },
    setGamePreviewModal: function setGamePreviewModal(state, payload) {
      state.gamePreviewModal = payload;
    },
    setGiftPopup: function setGiftPopup(state) {
      // show temporarily disabled
      state.giftPopup = false;
    },
    setConfirmation: function setConfirmation(state, payload) {
      state.confirmation = payload;
    },
    setChangeNumber: function setChangeNumber(state, payload) {
      state.changeNumber = payload;
    },
    setSignUpBonusProgress: function setSignUpBonusProgress(state, payload) {
      state.signUpBonusProgress = payload;
    },
    setProfileForm: function setProfileForm(state, payload) {
      state.profileForm = payload;
    },
    setAddFacebook: function setAddFacebook(state, payload) {
      state.addFacebook = payload;
    },
    setSignUpBonusPopup: function setSignUpBonusPopup(state, payload) {
      state.signUpBonusPopup = payload;
    },
    setGetAppModal: function setGetAppModal(state, payload) {
      state.getAppModal = payload;
    },
    setGetAppModalDesktop: function setGetAppModalDesktop(state, payload) {
      state.getAppModalDesktop = payload;
    },
    setGetAppModalSoon: function setGetAppModalSoon(state, payload) {
      state.getAppModalSoon = payload;
    },
    setPresetPackageModal: function setPresetPackageModal(state, payload) {
      state.presetPackageModal = payload;
    },
    setDailyWheelPopup: function setDailyWheelPopup(state, payload) {
      state.dailyWheelPopup = payload;
    },
    setVerificationPromoModal: function setVerificationPromoModal(state, payload) {
      state.verificationPromoModal = payload;
    },
    setRewardsModal: function setRewardsModal(state, payload) {
      state.rewardsModal = payload;
    },
    setMagicBoxesInfoModal: function setMagicBoxesInfoModal(state, payload) {
      state.magicBoxesInfoModal = payload;
    }
  },
  actions: {
    setConfirmInfoPopup: function setConfirmInfoPopup(_ref, payload) {
      var commit = _ref.commit;
      commit('setConfirmInfoPopup', payload);
    },
    setDailyStreakPopup: function setDailyStreakPopup(_ref2, payload) {
      var commit = _ref2.commit;
      commit('setDailyStreakPopup', payload);
    },
    setReferralPopup: function setReferralPopup(_ref3, payload) {
      var commit = _ref3.commit;
      commit('setReferralPopup', payload);
    },
    setWelcomePopup: function setWelcomePopup(_ref4, payload) {
      var commit = _ref4.commit;
      commit('setWelcomePopup', payload);
    },
    setAuthModal: function setAuthModal(_ref5, payload) {
      var commit = _ref5.commit,
          state = _ref5.state;
      if (payload.isOpen && !payload.activeTab) payload.activeTab = 'signup';
      payload.socialBlockException = !payload.socialBlockException && this.$router.currentRoute.query.openModal === 'register1' ? state.authModal.socialBlockException : payload.socialBlockException || [];
      commit('setAuthModal', payload);
    },
    setAuthModalErrorMessage: function setAuthModalErrorMessage(_ref6, payload) {
      var commit = _ref6.commit;
      commit('setAuthModalErrorMessage', payload);
    },
    setRegistrationCompleteModal: function setRegistrationCompleteModal(_ref7, payload) {
      var commit = _ref7.commit;
      commit('setRegistrationCompleteModal', payload);
    },
    setEmailConfirmationModal: function setEmailConfirmationModal(_ref8, payload) {
      var commit = _ref8.commit;
      commit('setEmailConfirmationModal', payload);
    },
    setTermModal: function setTermModal(_ref9, payload) {
      var commit = _ref9.commit;
      commit('setTermModal', payload);
    },
    setCheckEmailModal: function setCheckEmailModal(_ref10, payload) {
      var commit = _ref10.commit;
      commit('setCheckEmailModal', payload);
    },
    setGameModal: function setGameModal(_ref11, payload) {
      var commit = _ref11.commit;
      console.log('setGameModal');
      commit('setGameModal', payload);
    },
    setBonusDetails: function setBonusDetails(_ref12, payload) {
      var commit = _ref12.commit;
      commit('setBonusDetails', payload);
    },
    setExchangeCashModal: function setExchangeCashModal(_ref13, payload) {
      var commit = _ref13.commit;
      commit('setExchangeCashModal', payload);
    },
    setDeactivatePromoModal: function setDeactivatePromoModal(_ref14, payload) {
      var commit = _ref14.commit;
      commit('setDeactivatePromoModal', payload);
    },
    setProfileRedirectModal: function setProfileRedirectModal(_ref15, payload) {
      var commit = _ref15.commit;
      commit('setProfileRedirectModal', payload);
    },
    setAutoLockModal: function setAutoLockModal(_ref16, payload) {
      var commit = _ref16.commit;
      commit('setAutoLockModal', payload);
    },
    setMenuMobile: function setMenuMobile(_ref17, payload) {
      var commit = _ref17.commit;
      commit('setMenuMobile', payload);
    },
    setVipClubModal: function setVipClubModal(_ref18, payload) {
      var commit = _ref18.commit;
      commit('setVipClubModal', payload);
    },
    setSwitchModal: function setSwitchModal(_ref19, payload) {
      var commit = _ref19.commit;
      commit('setSwitchModal', payload);
    },
    setGamePreviewModal: function setGamePreviewModal(_ref20, payload) {
      var commit = _ref20.commit;
      commit('setGamePreviewModal', payload);
    },
    setGiftPopup: function setGiftPopup(_ref21, payload) {
      var commit = _ref21.commit;
      commit('setGiftPopup', payload);
    },
    setConfirmation: function setConfirmation(_ref22, payload) {
      var commit = _ref22.commit;
      commit('setConfirmation', payload);
    },
    setChangeNumber: function setChangeNumber(_ref23, payload) {
      var commit = _ref23.commit;
      commit('setChangeNumber', payload);
    },
    setSignUpBonusProgress: function setSignUpBonusProgress(_ref24, payload) {
      var commit = _ref24.commit;
      commit('setSignUpBonusProgress', payload);
    },
    setProfileForm: function setProfileForm(_ref25, payload) {
      var commit = _ref25.commit;
      commit('setProfileForm', payload);
    },
    setAddFacebook: function setAddFacebook(_ref26, payload) {
      var commit = _ref26.commit;
      commit('setAddFacebook', payload);
    },
    setSignUpBonusPopup: function setSignUpBonusPopup(_ref27, payload) {
      var commit = _ref27.commit;
      commit('setSignUpBonusPopup', payload);
    },
    setReferralAppModal: function setReferralAppModal(_ref28, payload) {
      var commit = _ref28.commit;
      this.$cookies.set('showReferralPopup', '1', {
        expires: '365d',
        path: '/'
      });
      commit('setReferralPopup', payload);
    },
    setGetAppModal: function setGetAppModal(_ref29, payload) {
      var commit = _ref29.commit;
      commit('setGetAppModal', payload);
    },
    setGetAppModalDesktop: function setGetAppModalDesktop(_ref30, payload) {
      var commit = _ref30.commit;
      commit('setGetAppModalDesktop', payload);
    },
    setGetAppModalSoon: function setGetAppModalSoon(_ref31, payload) {
      var commit = _ref31.commit;
      commit('setGetAppModalSoon', payload);
    },
    setPresetPackageModal: function setPresetPackageModal(_ref32, payload) {
      var commit = _ref32.commit;

      if (payload.isOpen) {
        this.$cookies.set('presetPackageModal', 'onReload', {
          expires: '1h',
          path: '/'
        });
      }

      commit('setPresetPackageModal', payload);
    },
    setDailyWheelPopup: function setDailyWheelPopup(_ref33, payload) {
      var commit = _ref33.commit;
      commit('setDailyWheelPopup', payload);
    },
    setVerificationPromoModal: function setVerificationPromoModal(_ref34, payload) {
      var commit = _ref34.commit,
          rootGetters = _ref34.rootGetters;
      var verificationStatus = rootGetters['player/verificationStatus'];
      if (payload.isOpen && (verificationStatus === 'approved' || verificationStatus === 'rejected')) return;

      if (!payload.isOpen && !this.$cookies.has('verificationPromoModal')) {
        this.$cookies.set('verificationPromoModal', '1', {
          expires: 1,
          path: '/'
        });
      }

      commit('setVerificationPromoModal', payload);
    },
    setRewardsModal: function setRewardsModal(_ref35, payload) {
      var commit = _ref35.commit;
      commit('setRewardsModal', payload);
    },
    setMagicBoxesInfoModal: function setMagicBoxesInfoModal(_ref36, payload) {
      var commit = _ref36.commit;
      commit('setMagicBoxesInfoModal', payload);
    }
  }
};
import "core-js/modules/es6.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import BalanceCounter from '@/components/Shared/BalanceCounter';
export default {
  name: 'HeaderBalance',
  components: {
    BalanceCounter: BalanceCounter
  },
  computed: {
    gameMode: function gameMode() {
      return this.$store.getters['player/gameMode'];
    },
    isSweepStakes: function isSweepStakes() {
      return this.gameMode === 'SweepStakes';
    },
    balance: function balance() {
      return this.$store.getters['balance/playerBalance'];
    },
    balanceWinnings: function balanceWinnings() {
      return this.$store.getters['balance/playerBalanceWinnings'];
    },
    winningsBalance: function winningsBalance() {
      if (this.balance && this.balance.winnings) {
        return Number(this.balanceWinnings);
      }

      return 0.00;
    },
    tourPointsBalance: function tourPointsBalance() {
      if (this.balance && this.balance.tourPoints) {
        return Number(this.balance.tourPoints);
      }

      return 0;
    }
  },
  methods: {// async switchTo() {
    //   this.$store.ga.clickButton({
    //     location: 'header',
    //     buttonName: !this.isSweepStakes ? 'switch_promotional' : 'switch_tourney',
    //   });
    //   await this.$store.dispatch(!this.isSweepStakes ? 'player/switchToSweeps' : 'player/switchToTourny');
    // },
  }
};
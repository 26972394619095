import Platform from 'quasar/src/plugins/Platform.js';import scroll from 'quasar/src/utils/scroll.js';;
var getScrollTarget = scroll.getScrollTarget,
    setScrollPosition = scroll.setScrollPosition;
export default (function (_ref) {
  var store = _ref.store;
  store.$ui = {
    scrollToElement: function scrollToElement(el) {
      el = document.getElementById(el);
      var target = getScrollTarget(el);
      var offset = el.offsetTop;
      var duration = Platform.is.safari ? null : 300;
      setScrollPosition(target, offset, duration);
    }
  };
});
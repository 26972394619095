//
//
//
//
export default {
  name: 'app-support',
  computed: {
    mirrors: function mirrors() {
      return this.$store.getters['app/mirrors'];
    }
  }
};
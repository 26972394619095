import "core-js/modules/es6.function.name.js";
export default {
  computed: {
    routePath: function routePath() {
      if (this.$route.name === 'games') {
        var path = this.$route.params.pathMatch;
        return path.substring(0, path.length - 1);
      }

      return '';
    }
  },
  methods: {// linkTo({ page, url }) {
    //   return this.$store.$routerHelper.linkTo({ page, params: url });
    // },
  }
};
import { render, staticRenderFns } from "./LazyComponent.vue?vue&type=template&id=90087896&scoped=true&lang=pug&"
import script from "./LazyComponent.vue?vue&type=script&lang=js&"
export * from "./LazyComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90087896",
  null
  
)

export default component.exports
import QIntersection from 'quasar/src/components/intersection/QIntersection.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QIntersection});

import _defineProperty from "/var/www/piastercreek.com/piastercreek.com/funrize/node_modules/@babel/runtime-corejs2/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.regexp.replace.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
export default {
  namespaced: true,
  state: function state() {
    return {
      defaultLang: 'en',
      availableLanguages: [],
      lang: null,
      i18nMessages: {},
      qMessages: {},
      hreflangs: null
    };
  },
  getters: {
    lang: function lang(state) {
      return state.lang;
    },
    getI18nMessagesByLang: function getI18nMessagesByLang(state) {
      return function (lang) {
        return state.i18nMessages[lang];
      };
    },
    getQMessagesByLang: function getQMessagesByLang(state) {
      return function (lang) {
        return state.qMessages[lang];
      };
    },
    defaultLang: function defaultLang(state) {
      return state.defaultLang;
    },
    availableLanguages: function availableLanguages(state) {
      return state.availableLanguages;
    },
    availableLanguagesForChange: function availableLanguagesForChange(state) {
      return state.availableLanguages.filter(function (lang) {
        return lang.length === 2;
      });
    },
    linkTo: function linkTo() {
      return function (_ref) {
        var page = _ref.page,
            params = _ref.params;
        var preparedParams = params ? params.replace(/^\/+/, '').replace(/\/*$/, '') : '';

        if (preparedParams) {
          return "/".concat(page, "/").concat(preparedParams);
        }

        return "/".concat(page);
      };
    },
    hreflangs: function hreflangs(state) {
      return state.hreflangs;
    }
  },
  mutations: {
    SET_DEFAULT_LANG: function SET_DEFAULT_LANG(state, payload) {
      state.defaultLang = payload;
    },
    SET_LANGUAGES: function SET_LANGUAGES(state, payload) {
      state.availableLanguages = payload;
    },
    SET_LANG: function SET_LANG(state, payload) {
      state.lang = payload;
    },
    I18N_SET_MESSAGES: function I18N_SET_MESSAGES(state, payload) {
      var lang = payload.lang,
          i18nMessages = payload.i18nMessages;
      state.i18nMessages = _objectSpread(_objectSpread({}, state.i18nMessages), {}, _defineProperty({}, lang, i18nMessages));
    },
    Q_SET_MESSAGES: function Q_SET_MESSAGES(state, payload) {
      var lang = payload.lang,
          qMessages = payload.qMessages;
      state.qMessages = _objectSpread(_objectSpread({}, state.qMessages), {}, _defineProperty({}, lang, qMessages));
    },
    SET_HREFLANGS: function SET_HREFLANGS(state, payload) {
      state.hreflangs = payload;
    }
  },
  actions: {
    setDefaultLang: function setDefaultLang(_ref2, payload) {
      var commit = _ref2.commit;
      commit('SET_DEFAULT_LANG', payload);
    },
    setAvailableLanguages: function setAvailableLanguages(_ref3, payload) {
      var commit = _ref3.commit;
      commit('SET_LANGUAGES', payload);
    },
    setLang: function setLang(_ref4, payload) {
      var commit = _ref4.commit;
      commit('SET_LANG', payload);
    },
    setI18nMessages: function setI18nMessages(_ref5, payload) {
      var commit = _ref5.commit;
      commit('I18N_SET_MESSAGES', payload);
    },
    setQMessages: function setQMessages(_ref6, payload) {
      var commit = _ref6.commit;
      commit('Q_SET_MESSAGES', payload);
    },
    setHreflangs: function setHreflangs(_ref7, payload) {
      var commit = _ref7.commit;
      var hreflangs = payload.alternates.reduce(function (result, next) {
        result[next.hreflang] = {
          rel: 'alternate',
          href: next.href,
          hreflang: next.hreflang
        };
        return result;
      }, {});
      commit('SET_HREFLANGS', hreflangs);
    }
  }
};
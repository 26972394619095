import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import pages from '@/dictionaries/pagesData';
export default {
  namespaced: true,
  state: function state() {
    return {
      route: null,
      links: []
    };
  },
  getters: {
    hasLinks: function hasLinks(state) {
      return state.links.length > 0;
    },
    pageSections: function pageSections(state, getters, rootState, rootGetters) {
      return state.links.filter(function (section) {
        if (typeof section.displayed === 'boolean') return section.displayed;
        var sectionsState = rootGetters[section.displayed];
        return sectionsState != null ? sectionsState[section.id] !== false : true;
      });
    },
    activeLink: function activeLink(state) {
      var activeLink = state.links.find(function (link) {
        return link.isIntersection === true;
      });
      if (activeLink) return activeLink.id;
      return null;
    },
    route: function route(state) {
      return state.route;
    },
    links: function links(state) {
      return state.links.map(function (item) {
        return item.isIntersection;
      });
    }
  },
  mutations: {
    setSidebarState: function setSidebarState(state, payload) {
      state.isOpen = payload;
    },
    setLinks: function setLinks(state, newLinks) {
      state.links = newLinks;
    },
    setLinkState: function setLinkState(state, link) {
      var index = state.links.findIndex(function (item) {
        return link.id === item.id;
      });

      if (index > -1 && state.links[index].isIntersection !== link.isIntersecting) {
        state.links[index].isIntersection = link.isIntersecting;
      }
    },
    setSectionsState: function setSectionsState(state, sections) {
      state.links = sections;
    },
    setRoute: function setRoute(state, path) {
      if (state.route !== path) state.route = path;
    }
  },
  actions: {
    updateSidebarState: function updateSidebarState(_ref, payload) {
      var commit = _ref.commit;
      commit('setSidebarState', payload);
    },
    updateRoute: function updateRoute(_ref2, path) {
      var commit = _ref2.commit;
      if (path === 'issues') return;
      var pageSections = pages[path].sections.filter(function (s) {
        if (s.projectContext == null || s.projectContext.length === 0) return true;
        return s.projectContext.includes(process.env.PROJECT_CONTEXT);
      });
      commit('setRoute', path);
      if (pageSections) commit('setLinks', pageSections);
    },
    updateLinkState: function updateLinkState(_ref3, link) {
      var commit = _ref3.commit;
      commit('setLinkState', link);
    }
  }
};
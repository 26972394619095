import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.number.parse-int.js";
import Vue from 'vue';

function numberFormat(number, digitsMin) {
  var _number$split$;

  var digitsMax = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
  var digitsMinComputed = digitsMin !== null && digitsMin !== void 0 ? digitsMin : (typeof number === 'string' ? (_number$split$ = number.split('.')[1]) === null || _number$split$ === void 0 ? void 0 : _number$split$.length : 0) || 0;
  return Number(number).toLocaleString('en-US', {
    minimumFractionDigits: digitsMinComputed,
    maximumFractionDigits: digitsMax
  });
}

export default function () {
  Vue.filter('currency', function (value, digitsMin) {
    return numberFormat(Number.parseInt(value, 10), digitsMin);
  });
  Vue.filter('currencyMultiply100', function (value, digitsMin) {
    return numberFormat(Number.parseInt(value, 10) * 100, digitsMin);
  });
  Vue.prototype.numberFormat = numberFormat;
}
//
//
//
//
//
//
//
//
//
//
//
import I18n from '@/mixins/I18n';
export default {
  mixins: [I18n],
  props: {
    section: Object,
    titleVars: Object
  },
  methods: {
    clicked: function clicked() {
      this.$emit('toggle');
    }
  }
};
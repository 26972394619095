import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/var/www/piastercreek.com/piastercreek.com/funrize/node_modules/@babel/runtime-corejs2/helpers/defineProperty";
import "core-js/modules/es6.array.filter.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import Vue from 'vue';
import dayjs from 'dayjs';
import * as calendar from 'dayjs/plugin/calendar'; // Vue.use(dayjs);

function getDate(props) {
  var defaultProps = {
    sec: 0,
    min: 0,
    days: 0,
    months: 0,
    years: 0
  };

  var targetDate = _objectSpread(_objectSpread({}, defaultProps), props);

  var date = new Date();
  date.setSeconds(date.getSeconds() + targetDate.sec);
  date.setMinutes(date.getMinutes() + targetDate.min);
  date.setDate(date.getDate() + targetDate.days);
  date.setMonth(date.getMonth() + targetDate.months);
  date.setFullYear(date.getFullYear() + targetDate.years);
  return date;
}

function getDateISOString(props) {
  return this.getDate(props).toISOString();
}

function getFormatDate(date) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'D MMM HH:mm';
  return dayjs(date).format(format);
}

function dataFormat(referenceTime, useTime, needToShowYear) {
  return function () {
    var refMinutes = referenceTime.get('m');
    var refHour = referenceTime.get('h');
    var refDay = referenceTime.get('D');
    var refMonth = referenceTime.get('M');
    var refYear = referenceTime.get('y');
    var minutes = refMinutes >= 10 ? refMinutes : "0".concat(refMinutes);
    var hours = refHour >= 10 ? refHour : "0".concat(refHour);
    var day = refDay >= 10 ? refDay : "0".concat(refDay);
    var month = refMonth;
    var year = needToShowYear || refMonth === 0 || refMonth === 11 ? refYear : '';
    var lexicalPayload = year && useTime ? ', in ' : '';
    var time = useTime ? "".concat(lexicalPayload).concat(hours, ":").concat(minutes) : '';
    return "".concat(day, " ").concat(month, " ").concat(year).concat(time);
  };
}

function getTime(referenceTime) {
  var refMinutes = referenceTime.get('m');
  var refHour = referenceTime.get('h');
  var minutes = refMinutes >= 10 ? refMinutes : "0".concat(refMinutes);
  var hours = refHour >= 10 ? refHour : "0".concat(refHour);
  return "".concat(hours, ":").concat(minutes);
}

export default function () {
  Vue.filter('periodWithYear', function (payload) {
    // if dates have different years then show year
    var useYear = false;
    if (dayjs(payload.dateFrom).get('y') !== dayjs(payload.dateTill).get('y')) useYear = true;
    return "".concat(dataFormat(dayjs(payload.dateFrom), false, useYear)(), " - ").concat(dataFormat(dayjs(payload.dateTill), false, useYear)());
  });
  Vue.filter('date', function (value) {
    var referenceTime = dayjs(value);
    return dayjs().calendar(referenceTime, {
      /* BUG in dayjs: https://github.com/iamkun/dayjs/issues/1226 */
      lastDay: 'yesterday',
      sameDay: 'today',
      nextDay: 'tomorrow',
      lastWeek: dataFormat(referenceTime),
      nextWeek: dataFormat(referenceTime),
      sameElse: dataFormat(referenceTime)
    });
  });
  Vue.filter('period', function (value) {
    var needToShowYear = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var referenceTime = dayjs(value);
    return dayjs().calendar(referenceTime, {
      /* BUG in dayjs: https://github.com/iamkun/dayjs/issues/1226 */
      lastDay: dataFormat(referenceTime, false, needToShowYear),
      sameDay: dataFormat(referenceTime, false, needToShowYear),
      nextDay: dataFormat(referenceTime, false, needToShowYear),
      lastWeek: dataFormat(referenceTime, false, needToShowYear),
      nextWeek: dataFormat(referenceTime, false, needToShowYear),
      sameElse: dataFormat(referenceTime, false, needToShowYear)
    });
  });
  Vue.filter('dateWithTime', function (value) {
    var referenceTime = dayjs(value);
    return dayjs().calendar(referenceTime, {
      /* BUG in dayjs: https://github.com/iamkun/dayjs/issues/1226 */
      lastDay: "tomorrow ".concat(getTime(referenceTime)),
      sameDay: "today ".concat(getTime(referenceTime)),
      nextDay: "yesterday ".concat(getTime(referenceTime)),
      lastWeek: dataFormat(referenceTime, true),
      nextWeek: dataFormat(referenceTime, true),
      sameElse: dataFormat(referenceTime, true)
    });
  });
  Vue.filter('dateWithYear', function (payload) {
    var referenceTime = dayjs(payload);
    var monthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var refYear = referenceTime.get('y');
    var refMonth = referenceTime.get('M');
    var refDay = referenceTime.get('D');
    var month = monthArray[refMonth];
    var day = refDay >= 10 ? refDay : "0".concat(refDay);
    return "".concat(day, " ").concat(month, " ").concat(refYear);
  });
  dayjs.extend(calendar);
  Vue.prototype.getDate = getDate;
  Vue.prototype.getDateISOString = getDateISOString;
  Vue.prototype.getFormatDate = getFormatDate;
}
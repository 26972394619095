import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/var/www/piastercreek.com/piastercreek.com/funrize/node_modules/@babel/runtime-corejs2/helpers/defineProperty";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.function.name.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var timeToShow = process.env.BANNER_PRESET_PACKAGE_TIMER;
export default {
  namespaced: true,
  state: function state() {
    return {
      timer: null,
      availableTillTimer: null,
      availableTill: null,
      delayed: false
    };
  },
  getters: {
    timer: function timer(state) {
      return state.timer;
    },
    availableTillTimer: function availableTillTimer(state) {
      return state.availableTillTimer;
    },
    availableTill: function availableTill(state) {
      return state.availableTill;
    },
    delayed: function delayed(state) {
      return state.delayed;
    }
  },
  mutations: {
    setTimer: function setTimer(state, payload) {
      state.timer = payload;
    },
    setAvailableTillTimer: function setAvailableTillTimer(state, payload) {
      state.availableTillTimer = payload;
    },
    setBannerPresetAvailableTill: function setBannerPresetAvailableTill(state, payload) {
      state.availableTill = payload;
    },
    incBannerPresetAvailableTill: function incBannerPresetAvailableTill(state) {
      state.availableTill -= 1;
    },
    setDelayed: function setDelayed(state, payload) {
      state.delayed = payload;
    }
  },
  actions: {
    showPackage: function showPackage(_ref, payload) {
      var rootGetters = _ref.rootGetters;
      if (!rootGetters['player/popupPresetPackage']) return;
      this.dispatch('modals/setPresetPackageModal', _objectSpread({
        isOpen: true
      }, payload));
    },
    showPackageAfterAuth: function showPackageAfterAuth(_ref2) {
      var _this = this;

      var rootGetters = _ref2.rootGetters;
      if (!rootGetters['player/popupPresetPackage']) return;
      setTimeout(function () {
        _this.gaBannerPresetPackage.openPopup({
          formName: 'bonus_offer',
          quantity: rootGetters['player/profile'].depositsCount,
          location: 'login'
        });
      }, 1500);
      this.dispatch('modals/setPresetPackageModal', {
        isOpen: true
      });
    },
    showPackageDelayed: function showPackageDelayed(_ref3) {
      var _this2 = this;

      var rootGetters = _ref3.rootGetters,
          getters = _ref3.getters;
      if (!rootGetters['player/popupPresetPackage']) return;

      if (!getters.timer) {
        setTimeout(function () {
          _this2.gaBannerPresetPackage.openPopup({
            formName: 'bonus_offer',
            quantity: rootGetters['player/profile'].depositsCount,
            location: 'reminder'
          });

          _this2.dispatch('modals/setPresetPackageModal', {
            isOpen: true
          });
        }, 0);
      }
    },
    showPackageInTime: function showPackageInTime(_ref4) {
      var _this3 = this;

      var rootGetters = _ref4.rootGetters,
          getters = _ref4.getters,
          commit = _ref4.commit;
      // Showing modal every timeToShow period
      if (!rootGetters['player/popupPresetPackage']) return;
      if (!timeToShow) return;
      clearTimeout(getters.timer);
      commit('setDelayed', false);
      var timer = setTimeout(function () {
        commit('setTimer', null);
        var confirmationPopup = rootGetters['modals/confirmation'].isOpen;

        if (rootGetters['modals/gameModal'].isOpen || _this3.$cash.isOpen() || ['issues', 'game', 'profile'].includes(_this3.$router.currentRoute.name) || confirmationPopup) {
          if (!confirmationPopup) {
            commit('setDelayed', true);
          }

          return;
        }

        setTimeout(function () {
          _this3.gaBannerPresetPackage.openPopup({
            formName: 'bonus_offer',
            quantity: rootGetters['player/profile'].depositsCount,
            location: 'reminder'
          });
        }, 1500);

        _this3.dispatch('modals/setPresetPackageModal', {
          isOpen: true
        });
      }, timeToShow);
      commit('setTimer', timer);
    },
    runPeriodicalPackageTimer: function runPeriodicalPackageTimer(_ref5) {
      var _this4 = this;

      var rootGetters = _ref5.rootGetters,
          getters = _ref5.getters,
          dispatch = _ref5.dispatch,
          commit = _ref5.commit;
      if (!rootGetters['player/popupPresetPackageAvailableTill']) return;
      clearInterval(getters.availableTillTimer);
      commit('setBannerPresetAvailableTill', rootGetters['player/popupPresetPackageAvailableTill']);
      var availableTillTimer = setInterval(function () {
        commit('incBannerPresetAvailableTill');

        if (getters.availableTill <= 0) {
          _this4.dispatch('modals/setPresetPackageModal', {
            isOpen: false
          });

          dispatch('player/setPopupPresetPackage', null, {
            root: true
          });
          dispatch('removePackageTimers');
        }
      }, 1000);
      commit('setAvailableTillTimer', availableTillTimer);
    },
    removePackageTimers: function removePackageTimers(_ref6) {
      var commit = _ref6.commit,
          state = _ref6.state;
      clearTimeout(state.timer);
      clearInterval(state.availableTillTimer);
      commit('setTimer', null);
      commit('setAvailableTillTimer', null);
      commit('setDelayed', false);
    }
  }
};
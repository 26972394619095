var mockQuestData = {
  startDate: 'Fri, 28 Oct 2022 14:13:19 +0300',
  endDate: 'Fri, 30 Oct 2022 14:13:19 +0300',
  isRegister: false,
  isAvailable: true,
  // prize: null,
  prize: {
    place: 1,
    gold: 75000,
    stars: 3300,
    task: {
      all: 25,
      completed: 5
    }
  },
  grandPrize: {
    gold: 1000000,
    entries: 50000
  },
  leaders: [{
    rank: 1,
    firstName: 'Andrey',
    lastName: 'J',
    gold: 1000000,
    entries: 50000,
    stars: 160,
    login: 1
  }, {
    rank: 2,
    firstName: 'Makira L',
    lastName: 'J',
    gold: 800000,
    entries: 50,
    stars: 160,
    login: 1
  }, {
    rank: 3,
    firstName: 'Andrey',
    lastName: 'J',
    gold: 600000,
    entries: null,
    stars: 160,
    login: 7875422
  }, {
    rank: 4,
    firstName: 'Andrey',
    lastName: 'J',
    gold: 500000,
    entries: null,
    stars: 160,
    login: 1
  }, {
    rank: 5,
    firstName: 'Andrey',
    lastName: 'J',
    gold: 1000000,
    entries: 1,
    stars: 160,
    login: 1
  }, {
    rank: 6,
    firstName: 'Andrey',
    lastName: 'J',
    gold: 400000,
    entries: null,
    stars: 160,
    login: 1
  }, {
    rank: 7,
    firstName: 'Andrey',
    lastName: 'J',
    gold: 300000,
    entries: null,
    stars: 160,
    login: 1
  }, {
    rank: 8,
    firstName: 'Andrey',
    lastName: 'J',
    gold: 200000,
    entries: 10000000,
    stars: 160,
    login: 1
  }, {
    rank: 9,
    firstName: 'Andrey',
    lastName: 'J',
    gold: 1000000,
    entries: null,
    stars: 160,
    login: 1
  }, {
    rank: 10,
    firstName: 'Andrey',
    lastName: 'J',
    gold: 1000000,
    entries: null,
    stars: 160,
    login: 1
  }, {
    rank: 11,
    firstName: 'Andrey',
    lastName: 'J',
    gold: 1000000,
    entries: null,
    stars: 160,
    login: 1
  }, {
    rank: 12,
    firstName: 'Makira L',
    lastName: 'J',
    gold: 800000,
    entries: null,
    stars: 160,
    login: 1
  }, {
    rank: 13,
    firstName: 'Andrey',
    lastName: 'J',
    gold: 600000,
    entries: null,
    stars: 160,
    login: 1
  }, {
    rank: 14,
    firstName: 'Andrey',
    lastName: 'J',
    gold: 500000,
    entries: null,
    stars: 160,
    login: 1
  }, {
    rank: 15,
    firstName: 'Andrey',
    lastName: 'J',
    gold: 1000000,
    entries: null,
    stars: 160,
    login: 1
  }, {
    rank: 16,
    firstName: 'Andrey',
    lastName: 'J',
    gold: 400000,
    entries: null,
    stars: 160,
    login: 1
  }, {
    rank: 17,
    firstName: 'Andrey',
    lastName: 'J',
    gold: 300000,
    entries: null,
    stars: 160,
    login: 1
  }, {
    rank: 18,
    firstName: 'Andrey',
    lastName: 'J',
    gold: 200000,
    entries: null,
    stars: 160,
    login: 1
  }, {
    rank: 19,
    firstName: 'Andrey',
    lastName: 'J',
    gold: 1000000,
    entries: null,
    stars: 160,
    login: 1
  }, {
    rank: 20,
    firstName: 'Andrey',
    lastName: 'J',
    gold: 1000000,
    entries: null,
    stars: 160,
    login: 1
  }],
  progress: [{
    day: 1,
    id: 1,
    isEnded: true,
    isComplete: true,
    isLocked: false,
    prize: 130,
    icon: '1day',
    startDate: '12/12/2022 at 00:00',
    questList: [{
      icon: '1',
      title: 'login for x day',
      stars: 10,
      gold: 10000,
      entries: 5000,
      progress: null,
      isComplete: true
    }, {
      icon: '2',
      title: 'play 5 games',
      stars: 15,
      gold: 60000,
      entries: null,
      progress: {
        complete: 5,
        goal: 5
      },
      isComplete: true,
      link: '/issues/all-games/'
    }, {
      icon: '3',
      title: 'login for x day',
      stars: 20,
      gold: 80000,
      entries: null,
      progress: {
        complete: 5,
        goal: 5
      },
      isComplete: true
    }, {
      icon: '4',
      title: 'login for x day',
      stars: 50,
      gold: 150000,
      entries: null,
      progress: {
        complete: 100000,
        goal: 100000
      },
      isComplete: true
    }, {
      icon: '5',
      title: 'login for x day',
      stars: 35,
      gold: 150000,
      entries: null,
      progress: null,
      isComplete: true
    }]
  }, {
    day: 2,
    id: 2,
    isEnded: true,
    isComplete: true,
    isLocked: false,
    prize: 310,
    icon: '2day',
    startDate: '12/12/2022 at 00:00',
    questList: [{
      icon: '1',
      title: 'login for x day',
      stars: 10,
      gold: 10000,
      entries: null,
      progress: null,
      isComplete: true
    }, {
      icon: '2',
      title: 'play 5 games',
      stars: 15,
      gold: 60000,
      entries: null,
      progress: {
        complete: 5,
        goal: 5
      },
      isComplete: true
    }, {
      icon: '3',
      title: 'login for x day',
      stars: 20,
      gold: 80000,
      entries: null,
      progress: {
        complete: 5,
        goal: 5
      },
      isComplete: true
    }, {
      icon: '4',
      title: 'login for x day',
      stars: 50,
      gold: 150000,
      entries: null,
      progress: {
        complete: 100000,
        goal: 100000
      },
      isComplete: true
    }, {
      icon: '5',
      title: 'login for x day',
      stars: 35,
      gold: 150000,
      entries: null,
      progress: null,
      isComplete: true
    }]
  }, {
    day: 3,
    id: 3,
    isEnded: true,
    isComplete: false,
    isLocked: false,
    prize: 468,
    icon: '3day',
    startDate: '12/12/2022 at 00:00',
    questList: [{
      icon: '1',
      title: 'login for x day',
      stars: 10,
      gold: 10000,
      entries: null,
      progress: null,
      isComplete: true
    }, {
      icon: '2',
      title: 'play 5 games',
      stars: 15,
      gold: 60000,
      entries: 5000,
      progress: {
        complete: 5,
        goal: 5
      },
      isComplete: true
    }, {
      icon: '3',
      title: 'login for x day',
      stars: 20,
      gold: 80000,
      entries: null,
      progress: {
        complete: 5,
        goal: 5
      },
      isComplete: true
    }, {
      icon: '4',
      title: 'login for x day',
      stars: 50,
      gold: 150000,
      entries: null,
      progress: {
        complete: 20000,
        goal: 100000
      },
      isComplete: false
    } // {
    //   icon: '5',
    //   title: 'login for x day',
    //   stars: 35,
    //   gold: 150000,
    //   progress: null,
    // },
    ]
  }, {
    day: 4,
    id: 4,
    isEnded: false,
    isComplete: false,
    isLocked: false,
    prize: 620,
    icon: '4day',
    startDate: '12/12/2022 at 00:00',
    questList: [{
      icon: '1',
      title: 'login for x day',
      stars: 10,
      gold: 10000,
      entries: null,
      progress: null,
      isComplete: true
    }, {
      icon: '2',
      title: 'play 5 games',
      stars: 15,
      gold: 60000,
      entries: null,
      progress: {
        complete: 2,
        goal: 5
      },
      isComplete: false,
      link: '/issues/all-games/'
    }, {
      icon: '3',
      title: 'login for x day',
      stars: 2,
      gold: 8,
      entries: null,
      progress: {
        complete: 3,
        goal: 5
      },
      isComplete: false
    }, {
      icon: '4',
      title: 'login for x day',
      stars: 50,
      gold: 1500,
      entries: 200,
      progress: {
        complete: 0,
        goal: 100000
      },
      isComplete: false
    }, {
      icon: '5',
      title: 'login for x day',
      stars: 35,
      gold: 150000,
      entries: null,
      progress: null,
      isComplete: false
    }]
  }, {
    day: 5,
    id: 5,
    isEnded: false,
    isComplete: false,
    isLocked: true,
    prize: 775,
    icon: '5day',
    startDate: '12/12/2022 at 00:00',
    questList: [{
      icon: '1',
      title: 'login for x day',
      stars: 10,
      gold: 10000,
      entries: null,
      progress: null,
      isComplete: false
    }, {
      icon: '2',
      title: 'play 5 games',
      stars: 15,
      gold: 60000,
      entries: null,
      progress: {
        complete: 0,
        goal: 5
      },
      isComplete: false
    }, {
      icon: '3',
      title: 'login for x day',
      stars: 20,
      gold: 80000,
      entries: null,
      progress: {
        complete: 0,
        goal: 5
      },
      isComplete: false
    }, {
      icon: '4',
      title: 'login for x day',
      stars: 50,
      gold: 150000,
      entries: null,
      progress: {
        complete: 0,
        goal: 100000
      },
      isComplete: false
    }, {
      icon: '5',
      title: 'login for x day',
      stars: 35,
      gold: 150000,
      entries: null,
      progress: null,
      isComplete: false
    }]
  }]
};
var mockUncollectedQuestPrize = {
  questId: 1,
  dateStart: '2022-11-01',
  dateEnd: '2022-11-05',
  title: 'Test',
  playerData: {
    totalTasks: 25,
    completedTasks: 12,
    place: 2,
    prize: {
      entries: null,
      coins: 100,
      points: 120
    }
  }
};
var mockQuestBannerDataForGuest = {
  isAvailable: false,
  prize: {
    coins: 1000000,
    sc: null
  },
  questList: [{
    icon: '1',
    title: 'login for x day',
    stars: null,
    gold: 10000,
    progress: null,
    isComplete: false
  }, {
    icon: '2',
    title: 'play 5 games',
    stars: 15,
    gold: 60000,
    progress: {
      complete: 0,
      goal: 5
    },
    isComplete: false
  }, {
    icon: '3',
    title: 'login for x day',
    stars: 20,
    gold: 80000,
    progress: {
      complete: 0,
      goal: 5
    },
    isComplete: false
  }, {
    icon: '4',
    title: 'login for x day',
    stars: 50,
    gold: null,
    progress: {
      complete: 0,
      goal: 100000
    },
    isComplete: false
  }, {
    icon: '5',
    title: 'login for x day',
    stars: 35,
    gold: 150000,
    progress: null,
    isComplete: false
  }]
};
var mockQuestBannerDataForUser = {
  isAvailable: false,
  prize: {
    coins: 1000000,
    sc: 5000
  },
  questList: [{
    icon: '1',
    title: 'login for x day',
    stars: null,
    gold: 10000,
    progress: null,
    isComplete: true
  }, {
    icon: '2',
    title: 'play 5 games',
    stars: 15,
    gold: 60000,
    progress: {
      complete: 3,
      goal: 5
    },
    isComplete: false
  }, {
    icon: '3',
    title: 'login for x day',
    stars: 20,
    gold: 80000,
    progress: {
      complete: 5,
      goal: 5
    },
    isComplete: true
  }, {
    icon: '4',
    title: 'login for x day',
    stars: 50,
    gold: null,
    progress: {
      complete: 0,
      goal: 100000
    },
    isComplete: false
  }, {
    icon: '5',
    title: 'login for x day',
    stars: 35,
    gold: 150000,
    progress: null,
    isComplete: false
  }, {
    icon: '5',
    title: 'login for x day',
    stars: 35,
    gold: 150000,
    progress: null,
    isComplete: false
  }, {
    icon: '5',
    title: 'login for x day',
    stars: 35,
    gold: 150000,
    progress: null,
    isComplete: false
  }]
};
export { mockQuestData, mockUncollectedQuestPrize, mockQuestBannerDataForGuest, mockQuestBannerDataForUser };
import Vue from 'vue';
export default (function (_ref) {
  var store = _ref.store,
      ssrContext = _ref.ssrContext;

  if (!process.env.RAYGUN_API_KEY) {
    store.log = function (err) {
      console.error(err);
    };
  } else if (process.env.SERVER) {
    var raygunClient = ssrContext.res.raygunClient;

    store.log = function (err) {
      console.error(err);
      raygunClient.send(err, {
        errorHandlerFrom: 'Server: store.log'
      });
    };

    Vue.config.errorHandler = function (err) {
      console.error(err);
      raygunClient.send(err, {
        errorHandlerFrom: 'Server: Vue.config.errorHandler'
      });
    };
  } else if (process.env.CLIENT) {
    store.log = function (err) {
      console.error(err);
      window.rg4js('send', {
        error: err,
        customData: [{
          errorHandlerFrom: 'Server: store.log'
        }]
      });
    };

    Vue.config.errorHandler = function (err, vm, info) {
      console.error(err);
      window.rg4js('send', {
        error: err,
        customData: [{
          info: info,
          errorHandlerFrom: 'Client: Vue.config.errorHandler'
        }]
      });
    };
  }
});